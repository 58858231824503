import {
  type ChangeEvent,
  type MutableRefObject,
  useCallback,
  useState,
} from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
  generateNegativeTimestamp,
  useToast,
} from '@gbs-monorepo-packages/common';

import { type IGlobalStyle } from '../../../../constants/GlobalStyles';
import { COURSES } from '../../../../constants/RoutePaths';
import {
  type ICoursePage,
  type ITemplatePage,
} from '../../../../contexts/coursePage';
import { useCourse } from '../../../../hooks/useCourse';
import { useCoursePages } from '../../../../hooks/useCoursePages';
import { type ICoursePageDTO } from '../../../../services/coursePages';
import {
  type ICourseDTO,
  type ICourseSettingsProps,
  type IFontDTO,
  type IFontsList,
  type IGlobalStyleDTO,
  saveCourseFonts,
  updateGlobalStyleCourse,
} from '../../../../services/courses';
import { type ITemplatePageDTO } from '../../../../services/templates';
import { getRouteFrom } from '../../../../utils/getRoutes';
import { FontsModal } from '../FontsModal';
import { GlobalStyleModal } from '../GlobalStyleModal';
import { SavePageAsTemplateModal } from '../SavePageAsTemplateModal';
import { SettingCourseModal } from '../SettingCourseModal';
import { WarningGlobalStyleModal } from '../WarningGlobalStyleModal';
import {
  ActionCourseButton,
  ActionCourseContainer,
  ActionLabel,
  BackIcon,
  BackPageButton,
  ExtraCourseButton,
  ExtraCourseContainer,
  FontsIcon,
  Header,
  SettingsIcon,
  StyleIcon,
  TemplateIcon,
  TitleContainer,
  TitlePage,
} from './styles';

export interface IUpdatedSettingCourseHeader {
  indexSelected: number;
  coursePages: ICoursePage[];
}

export interface ISettingCourseHeaderProps {
  dataCy?: string;
  isAllowedToEdit?: boolean;
  isUserAdmin?: boolean;
  isUserManager?: boolean;
  isDropdownOpen?: boolean;
  lastCoursePages: MutableRefObject<ICoursePageDTO[]>;
  lastTemplatePage: MutableRefObject<ITemplatePageDTO[]>;
  isCreateCourse?: boolean;
  loading?: boolean;
  needSave?: boolean;
  onChangeTitle?: (newTitle: string) => void;
  onCourseFontsChange?: (newFonts: IFontDTO[]) => void;
  onDiscardCourse?: (newCoursePages: ICoursePage[]) => void;
  onDiscardTemplate?: (newTemplatePages: ITemplatePage[]) => void;
  onSaveModifications?: () => Promise<void>;
  onSavePageTemplate?: (
    title: string,
    templateOptions: number
  ) => Promise<void>;
  onSettingsChange?: (newCourse: ICourseSettingsProps) => void;
  onSaveGlobalStyle?: () => void;
  onUpdatePageTemplate?: () => Promise<void>;
  onBackButtonAditionalAction?: () => void;
}

export const SettingCourseHeader = ({
  dataCy = 'page-header',
  isAllowedToEdit = true,
  isDropdownOpen = false,
  isUserAdmin = true,
  isUserManager = true,
  isCreateCourse = true,
  lastCoursePages,
  lastTemplatePage,
  loading = false,
  needSave = false,
  onChangeTitle,
  onCourseFontsChange,
  onDiscardCourse,
  onDiscardTemplate,
  onSaveModifications,
  onSavePageTemplate,
  onUpdatePageTemplate,
  onSettingsChange,
  onSaveGlobalStyle,
  onBackButtonAditionalAction,
}: ISettingCourseHeaderProps): JSX.Element => {
  const { addToast } = useToast();

  const navigate = useNavigate();
  const { companyId = '', courseId = '' } = useParams();

  const [isFontsModalOpen, setIsFontsModalOpen] = useState(false);
  const [isModalGlobalStyleOpen, setIsModalGlobalStyleOpen] = useState(false);
  const [isWarningGlobalStyleOpen, setIsWarningGlobalStyleOpen] =
    useState(false);

  const [isModalSavePageAsTemplateOpen, setIsModalSavePageAsTemplateOpen] =
    useState(false);
  const [loadingSavePageAsTemplate, setLoadingSavePageAsTemplate] =
    useState(false);
  const [loadingSaveGlobalStyle, setLoadingSaveGlobalStyle] = useState(false);

  const [isModalCourseSettingsOpen, setIsModalCourseSettingsOpen] =
    useState(false);

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingWarningGlobalStyle, setLoadingWarningGlobalStyle] =
    useState(false);

  const {
    selectedCourse,
    courseSettings,
    updateCourse,
    updateCourseSettings,
    selectedTemplate,
    updateTemplate,
  } = useCourse();

  const { coursePages, overwriteCoursePages, overwriteTemplatePages } =
    useCoursePages();

  const handleGoBack = useCallback(() => {
    if (onBackButtonAditionalAction) {
      onBackButtonAditionalAction();
    }

    if (isCreateCourse) {
      navigate(generatePath(getRouteFrom(COURSES), { companyId }), {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  }, [companyId, navigate, isCreateCourse, onBackButtonAditionalAction]);

  const handleChangeTitle = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const newTitle = target.value;

    if (newTitle.length > 90) {
      return;
    }

    if (isCreateCourse && selectedCourse) {
      updateCourse({
        ...selectedCourse,
        title: newTitle,
      });

      onChangeTitle?.(newTitle);
    }

    if (!isCreateCourse && selectedTemplate) {
      updateTemplate({
        ...selectedTemplate,
        title: newTitle,
      });

      onChangeTitle?.(newTitle);
    }
  };

  const handleOpenFontsModal = () => {
    setIsFontsModalOpen(true);
  };

  const handleOpenGlobalStyleModal = useCallback(() => {
    if (needSave) {
      setIsWarningGlobalStyleOpen(true);
      return;
    }

    setIsModalGlobalStyleOpen(true);
  }, [needSave]);

  const handleAddFontsToCourse = async (fontsList: IFontsList) => {
    try {
      const course = await saveCourseFonts({
        courseId: Number(courseId),
        fonts: fontsList.fonts,
      });

      addToast({
        title: 'Course fonts updated!',
        styleType: 'success',
        dataCy: 'save-template-success-toast',
      });

      onCourseFontsChange?.(course.fonts);
      updateCourse(course);
      setIsFontsModalOpen(false);
    } catch (err) {
      addToast({
        title: 'Error loading custom fonts',
        description:
          'An error occurred. Please try again or contact Edge support.',
        styleType: 'error',
      });
    }
  };

  const handleDeclineAddFonts = () => {
    setIsFontsModalOpen(false);
  };

  const handleDeclineGlobalStyle = () => {
    setIsModalGlobalStyleOpen(false);
  };

  const handleDeclineWarningGlobalStyle = () => {
    setIsWarningGlobalStyleOpen(false);
  };

  const handleOpenSavePageAsTemplate = () => {
    setIsModalSavePageAsTemplateOpen(true);
  };

  const handleSavePageTemplate = useCallback(
    async (title: string, templateOptions: number) => {
      setLoadingSavePageAsTemplate(true);

      try {
        await onSavePageTemplate?.(title, templateOptions);
        setIsModalSavePageAsTemplateOpen(false);
      } catch (err) {
        addToast({
          title: 'Error saving global styles',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
        });
      }

      setLoadingSavePageAsTemplate(false);
    },
    [onSavePageTemplate, addToast]
  );

  const handleSaveGlobalStyle = useCallback(
    async (data: IGlobalStyle) => {
      setLoadingSaveGlobalStyle(true);
      if (!selectedCourse?.globalStyle) {
        setLoadingSaveGlobalStyle(false);
        addToast({
          title: 'Something went wrong',
          description: 'An error occurred while updating the global style',
          styleType: 'error',
          dataCy: 'error-toast',
        });
        return;
      }
      await updateGlobalStyleCourse({
        id: selectedCourse.globalStyle.id,
        ...data,
      })
        .then((response: IGlobalStyleDTO) => {
          const updated: ICourseDTO = {
            ...selectedCourse,
            // ...data is temporary until the backend returns the new fields
            globalStyle: { ...data, ...response },
          };

          updateCourse(updated);

          addToast({
            title: 'Success',
            description: 'Global style has been updated!',
            styleType: 'success',
            dataCy: 'success-toast',
            duration: 3000,
          });
          onSaveGlobalStyle?.();

          setLoadingSaveGlobalStyle(false);
          setIsModalGlobalStyleOpen(false);
        })
        .catch(() => {
          setLoadingSaveGlobalStyle(false);
          setIsModalGlobalStyleOpen(false);
          addToast({
            title: 'Error on save global styles',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
          });
        });
    },
    [addToast, selectedCourse, updateCourse]
  );

  const handleDeclineSavePageTemplate = useCallback(() => {
    setIsModalSavePageAsTemplateOpen(false);
  }, []);

  const handleOpenCourseSettings = () => {
    setIsModalCourseSettingsOpen(true);
  };

  const handleNewCourseSettings = useCallback(
    (
      passcodeProtected: boolean,
      passcode: string,
      viewURL: string,
      courseStatus: string
    ) => {
      if (!selectedCourse) {
        return;
      }

      const updatedSettings: ICourseSettingsProps = {
        passcodeProtected:
          passcodeProtected ?? selectedCourse.passcodeProtected,
        passcode: passcode ?? selectedCourse.passcode,
        viewUrl: viewURL ?? selectedCourse?.viewURL,
        status: courseStatus ?? selectedCourse?.status,
      };

      updateCourseSettings(updatedSettings);

      onSettingsChange?.(updatedSettings);
      setIsModalCourseSettingsOpen(false);
    },
    [onSettingsChange, selectedCourse, updateCourseSettings]
  );

  const handleDeclineNewCourseSettings = useCallback(() => {
    setIsModalCourseSettingsOpen(false);
  }, []);

  const handleDiscardCourse = useCallback(() => {
    const coursePagesIds = new Set(coursePages.map(({ id }) => id));
    let tempId = generateNegativeTimestamp();

    const newCoursePages = overwriteCoursePages(lastCoursePages.current);

    for (const newCoursePage of newCoursePages) {
      if (!coursePagesIds.has(newCoursePage.id)) {
        newCoursePage.isNew = true;
        newCoursePage.id = tempId;
        tempId--;
      }
    }

    onDiscardCourse?.(newCoursePages);
  }, [coursePages, lastCoursePages, onDiscardCourse, overwriteCoursePages]);

  const handleDiscardTemplate = useCallback(() => {
    const newTemplatePages = overwriteTemplatePages(lastTemplatePage.current);
    onDiscardTemplate?.(newTemplatePages);
  }, [lastTemplatePage, onDiscardTemplate, overwriteTemplatePages]);

  const handleSaveModificationsCourse = useCallback(async () => {
    if (!selectedCourse?.title.trim()) {
      addToast({
        title: 'Course title is empty',
        description:
          'Please make sure to provide a title for your course to proceed.',
        styleType: 'error',
        dataCy: 'error-toast-course-title',
      });
      return;
    }
    setLoadingSave(true);
    setIsWarningGlobalStyleOpen(false);

    await onSaveModifications?.();

    setLoadingSave(false);
  }, [onSaveModifications, addToast, selectedCourse]);

  const handleSaveWarningGlobalStyle = useCallback(async () => {
    if (!selectedCourse?.title.trim()) {
      addToast({
        title: 'Course title is empty',
        description:
          'Please make sure to provide a title for your course to proceed.',
        styleType: 'error',
        dataCy: 'error-toast-course-title',
      });
      return;
    }
    setLoadingWarningGlobalStyle(true);

    await onSaveModifications?.().finally(() => {
      setLoadingWarningGlobalStyle(false);
      setIsWarningGlobalStyleOpen(false);
      setIsModalGlobalStyleOpen(true);
    });
  }, [onSaveModifications, addToast, selectedCourse]);

  const handleSaveModificationsTemplate = useCallback(async () => {
    if (!selectedTemplate?.title.trim()) {
      addToast({
        title: 'Template title is empty',
        description:
          'Please make sure to provide a title for your template to proceed.',
        styleType: 'error',
        dataCy: 'error-toast-template-title',
      });
      return;
    }
    setLoadingSave(true);

    await onUpdatePageTemplate?.();

    setLoadingSave(false);
  }, [onUpdatePageTemplate, addToast, selectedTemplate]);

  const titleHeader =
    (isCreateCourse
      ? selectedCourse?.title ?? 'Course Name'
      : selectedTemplate?.title) ?? '';

  const actionButtonsHeaderCreateCourse = (
    <>
      <ExtraCourseButton
        data-cy="course-global-style-button"
        disabled={loading || !isAllowedToEdit}
        onClick={handleOpenGlobalStyleModal}
      >
        <StyleIcon />
        <ActionLabel data-cy="button-globalStyle">Global Style</ActionLabel>
      </ExtraCourseButton>
      <ExtraCourseButton
        data-cy="course-fonts-button"
        disabled={loading || !isAllowedToEdit}
        onClick={handleOpenFontsModal}
      >
        <FontsIcon />
        <ActionLabel data-cy="button-fonts">Fonts</ActionLabel>
      </ExtraCourseButton>
      <ExtraCourseButton
        data-cy="page-save-template-button"
        disabled={loading || !isAllowedToEdit}
        onClick={handleOpenSavePageAsTemplate}
      >
        <TemplateIcon />
        <ActionLabel data-cy="button-saveTemplate">Save Template</ActionLabel>
      </ExtraCourseButton>
      <ExtraCourseButton
        data-cy="button-extraCourse"
        disabled={loading || !isAllowedToEdit}
        onClick={handleOpenCourseSettings}
      >
        <SettingsIcon />
        <ActionLabel data-cy="button-settingsCourse">Settings</ActionLabel>
      </ExtraCourseButton>
    </>
  );

  const actionButtonsSaveTemplate = (
    <ActionCourseButton
      disabled={loading || !isAllowedToEdit}
      loading={loadingSave}
      dataCy="buttonSave-modificationsTemplate"
      onClick={() => {
        void handleSaveModificationsTemplate();
      }}
    >
      Save Template
    </ActionCourseButton>
  );

  const actionButtonsUpdateCourse = (
    <ActionCourseButton
      disabled={!needSave}
      loading={loadingSave}
      dataCy="buttonSave-modificationsCourse"
      onClick={() => {
        void handleSaveModificationsCourse();
      }}
    >
      Save Course
    </ActionCourseButton>
  );

  return (
    <Header data-cy={dataCy}>
      <TitleContainer data-cy="course-info-container">
        <BackPageButton data-cy="button-back" onClick={handleGoBack}>
          <BackIcon />
        </BackPageButton>
        <TitlePage
          id="course-title-header"
          dataCy={titleHeader}
          disabled={loading || (isCreateCourse && !isAllowedToEdit)}
          onChange={handleChangeTitle}
        >
          {titleHeader}
        </TitlePage>
      </TitleContainer>
      <ExtraCourseContainer data-cy="course-extra-button-container">
        <>{isCreateCourse && actionButtonsHeaderCreateCourse}</>
      </ExtraCourseContainer>
      <ActionCourseContainer data-cy="course-action-button-container">
        {isAllowedToEdit && (
          <>
            <ActionCourseButton
              disabled={!needSave || loadingSave}
              styleType="text"
              dataCy="buttonDiscard-modificationsCourse"
              onClick={
                isCreateCourse ? handleDiscardCourse : handleDiscardTemplate
              }
            >
              Discard
            </ActionCourseButton>

            {isCreateCourse && actionButtonsUpdateCourse}
            {!isCreateCourse && actionButtonsSaveTemplate}
          </>
        )}
      </ActionCourseContainer>
      <FontsModal
        courseId={Number(courseId)}
        onAccept={handleAddFontsToCourse}
        onDecline={handleDeclineAddFonts}
        open={isFontsModalOpen}
        courseFonts={selectedCourse?.fonts ?? []}
        zIndex={10}
      />
      <WarningGlobalStyleModal
        loading={loadingWarningGlobalStyle}
        onAccept={handleSaveWarningGlobalStyle}
        onDecline={handleDeclineWarningGlobalStyle}
        open={isWarningGlobalStyleOpen}
        acceptText="Save Course"
        dataCy="save-course-dialogModal"
        mainText={`You need to save the course changes to edit the global style. Do you
      want to save them?`}
        zIndex={10}
      />
      <GlobalStyleModal
        courseId={Number(courseId)}
        loading={loadingSaveGlobalStyle}
        onAccept={handleSaveGlobalStyle}
        onDecline={handleDeclineGlobalStyle}
        open={isModalGlobalStyleOpen}
        zIndex={10}
        needSave={needSave}
      />
      <SavePageAsTemplateModal
        loading={loadingSavePageAsTemplate}
        onAccept={handleSavePageTemplate}
        onDecline={handleDeclineSavePageTemplate}
        open={isModalSavePageAsTemplateOpen}
        waitToOpen={isDropdownOpen}
        zIndex={10}
        isUserAdmin={isUserAdmin}
        isUserManager={isUserManager}
      />
      <SettingCourseModal
        courseTitle={selectedCourse?.title}
        onAccept={handleNewCourseSettings}
        onDecline={handleDeclineNewCourseSettings}
        courseSettings={courseSettings}
        open={isModalCourseSettingsOpen}
        waitToOpen={isDropdownOpen}
        zIndex={10}
      />
    </Header>
  );
};
