import { format } from 'date-fns';
import { produce } from 'immer';
import {
  type ChangeEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseDropdown,
  BaseDropdownItem,
  BreadcrumbsComponent,
  DefaultDescription,
  ETypeFolder,
  FormModal,
  type IApiThrowsError,
  type IColumn,
  type IPaginationMetaProps,
  type ISort,
  LIMIT_PAGE,
  ListLoading,
  Logger,
  PageContent,
  PageHeader,
  SearchBar,
  TableImageCell,
  TableRow,
  getRouteFrom,
  useBreadcrumbs,
  useToast,
} from '@gbs-monorepo-packages/common';

import { FOLDER } from '../../constants/RoutePaths';
import { useCompanies } from '../../hooks/useCompanies';
import {
  type ICreateFolderProps,
  type IFolderDTO,
  type IPaginationFolderDTO,
  type IUpdateFolderProps,
  createFolder,
  deleteFolder,
  getListFolderByClient,
  updateFolder,
} from '../../services/folders';
import { AddFolderModal } from './components/AddFolder';
import { EditFolderModal } from './components/EditFolder';
import {
  Container,
  DropdownButtonContainer,
  FolderIcon,
  SearchContainer,
  TableCellCustom,
  TableCustom,
} from './styles';

const DocumentHeaderColumns = [
  {
    id: 'name',
    name: 'Description',
    textAlign: 'start',
  },
  {
    id: 'updatedAt',
    name: 'Upload Date',
    textAlign: 'end',
  },
  {
    id: 'options',
    name: '',
    textAlign: 'center',
  },
];

export const FoldersDocuments = (): JSX.Element => {
  const { typeFolder = '', companyId = '' } = useParams();
  const { selectedCompany } = useCompanies();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const folderRoute = getRouteFrom(FOLDER);

  const { breadcrumbs, addBreadcrumb, removeBreadcrumb, clearBreadcrumbs } =
    useBreadcrumbs();

  const constTypeFolder =
    typeFolder === 'private' ? ETypeFolder.PRIVATE : ETypeFolder.PUBLIC;
  const TitlePageText =
    typeFolder === 'private'
      ? 'Private GBS Documents'
      : 'Public Employee Documents';

  const [loadingFolders, setLoadingFolders] = useState(false);
  const [loadingActions, setLoadingActions] = useState(false);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [folders, setFolders] = useState<IFolderDTO[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [sortOrder, setSortOrder] = useState<ISort | null>(null);

  const { addToast } = useToast();
  const { user } = useAuth();

  const [folderToEdit, setFolderEdit] = useState<IFolderDTO | null>(null);
  const [folderDelete, setFolderDelete] = useState<IFolderDTO | null>(null);
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isModalDeleteOpen = !!folderDelete;
  const userIsAdmin =
    user?.roles.includes('ROLE_ADMIN') === true ||
    user?.roles.includes('ROLE_MANAGER') === true;

  const toRouteSubFolder = useCallback(
    (folderId: number | string) =>
      generatePath(folderRoute, {
        companyId,
        typeFolder,
        folderId,
      }),
    [companyId, folderRoute, typeFolder]
  );

  const handleRowClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, item: IFolderDTO) => {
      const route = toRouteSubFolder(item.id);
      addBreadcrumb({
        name: item.name,
        url: route,
      });
      navigate(route, { state: { from: pathname } });
    },
    [addBreadcrumb, navigate, pathname]
  );

  const getFolders = useCallback(() => {
    setLoadingFolders(true);
    const clientId = companyId ?? 0;
    setSearch('');
    getListFolderByClient({
      clientId: String(clientId),
      page: 1,
      limit: LIMIT_PAGE,
      filter: '',
      search: String(constTypeFolder),
      sort: JSON.stringify(sortOrder),
    })
      .then((response: IPaginationFolderDTO) => {
        setFolders(response.data);
        setPaginationMeta(response.meta);
        if (
          selectedCompany?.defaultLmsFolder.id != null &&
          constTypeFolder === ETypeFolder.PUBLIC &&
          breadcrumbs?.length === 0
        ) {
          const defaultFolder = response.data.find(
            (folder) => folder.id === selectedCompany?.defaultLmsFolder.id
          );
          if (defaultFolder) {
            addBreadcrumb({
              name: defaultFolder.name,
              url: toRouteSubFolder(defaultFolder.id),
            });
            navigate(toRouteSubFolder(defaultFolder.id), {
              state: { from: pathname },
            });
          }
        }
      })
      .catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
      })
      .finally(() => {
        setLoadingFolders(false);
      });
  }, [companyId, constTypeFolder, sortOrder]);

  useEffect(() => {
    clearBreadcrumbs();
  }, [typeFolder, companyId]);

  useEffect(() => {
    if (breadcrumbs?.length === 0) {
      getFolders();
      addBreadcrumb({
        name: TitlePageText,
        url: pathname,
      });
    }
  }, [breadcrumbs]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setFolders([]);
        searchFolder(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    if (sortOrder) {
      searchFolder(true);
    }
  }, [sortOrder]);

  const searchFolder = (searchByButton = false) => {
    if (!loadingFolders || searchByButton) {
      setLoadingFolders(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      const clientId = companyId ?? 0;
      getListFolderByClient({
        clientId: String(clientId),
        page: pageAux + 1,
        limit: LIMIT_PAGE,
        filter: search ?? '',
        search: String(constTypeFolder),
        sort: JSON.stringify(sortOrder),
      })
        .then((response: IPaginationFolderDTO) => {
          if (!searchByButton) {
            setFolders(
              produce((draft) => {
                draft.push(...response.data);
              })
            );
            setPaginationMeta(response.meta);
          } else {
            setFolders(response.data);
            setPaginationMeta(response.meta);
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingFolders(false);
        });
    }
  };

  const addFolder = useCallback(
    async (folder: ICreateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);

        await createFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder created',
              styleType: 'success',
              dataCy: 'create-folder-success-toast',
              duration: 3000,
            });
            setOpenAddFolderModal(false);
            getFolders();
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on creating folder',
              description:
                error.response?.data.error &&
                error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'create-folder-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [addToast, getFolders, loadingActions]
  );

  const editFolder = useCallback(
    async (folder: IUpdateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);

        await updateFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder updated',
              styleType: 'success',
              dataCy: 'updated-folder-success-toast',
              duration: 3000,
            });
            getFolders();
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on updated folder',
              description:
                error.response?.data.error &&
                error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'updated-folder-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [addToast, getFolders, loadingActions]
  );

  const removeFolder = useCallback(() => {
    if (!folderDelete) {
      return;
    }

    if (!loadingActions) {
      setLoadingActions(true);

      deleteFolder({ id: folderDelete.id })
        .then(() => {
          addToast({
            title: 'Folder deleted',
            styleType: 'success',
            dataCy: 'delete-folder-success-toast',
            duration: 3000,
          });
          getFolders();
        })
        .catch(() => {
          addToast({
            title: 'Error on deleting folder',
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'delete-folder-error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setFolderDelete(null);
          setLoadingActions(false);
        });
    }
  }, [addToast, folderDelete, getFolders, loadingActions]);

  const handleDecline = useCallback(() => {
    setFolderEdit(null);
  }, []);

  const handleDeclineDelete = useCallback(() => {
    setFolderDelete(null);
  }, []);

  const formDate = (date: string) => {
    const dateAux = format(new Date(date), 'MM-dd-yyyy');
    return dateAux;
  };

  const removeBreadcrumbPage = useCallback(
    (index: number) => {
      removeBreadcrumb(index);
    },
    [removeBreadcrumb]
  );

  const canAddPublicFolder =
    (typeFolder === 'public' &&
      (user?.roles.includes('ROLE_ADMIN') === true ||
        user?.roles.includes('ROLE_MANAGER') === true)) ||
    typeFolder === 'private';

  return (
    <Container data-cy="document-container">
      <PageHeader
        title={TitlePageText}
        buttonText={canAddPublicFolder ? 'Add Folder' : ''}
        onClick={() => {
          setOpenAddFolderModal(true);
        }}
      />
      <PageContent>
        <SearchContainer>
          <SearchBar
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
            onClearInput={() => {
              setSearch('');
            }}
            search={search}
            maxLength={40}
            placeholder="Search folder"
            loading={loadingFolders}
            id="search-folder"
          />
        </SearchContainer>
        <BreadcrumbsComponent
          links={breadcrumbs}
          onClick={removeBreadcrumbPage}
        />
        {loadingFolders ? (
          <ListLoading />
        ) : (
          <TableCustom
            loading={loadingFolders}
            columns={DocumentHeaderColumns}
            dataLength={folders.length}
            next={searchFolder}
            hasMore={folders.length < (paginationMeta?.total ?? 0)}
            noItensMessage="No folders found."
            onClickHeader={(column: IColumn) => {
              setSortOrder({
                name: column.id,
                direction: sortOrder?.direction === 'ASC' ? 'DESC' : 'ASC',
              });
            }}
            columnSorted={sortOrder}
          >
            {folders.map((item) => (
              <TableRow
                key={item.id}
                onClick={(e) => {
                  handleRowClick(e, item);
                }}
              >
                <TableImageCell
                  text={item.name}
                  subtitle=""
                  dataCy="folder-card"
                  type="icon"
                >
                  <FolderIcon />
                </TableImageCell>
                <TableCellCustom
                  textAlign="end"
                  text={formDate(item.updatedAt ?? item.createdAt)}
                />
                {constTypeFolder === ETypeFolder.PUBLIC && !userIsAdmin ? (
                  <div></div>
                ) : (
                  <DropdownButtonContainer data-cy="dropdown-container">
                    <BaseDropdown
                      disabled={
                        constTypeFolder === ETypeFolder.PRIVATE &&
                        item.accountId !== user?.id &&
                        !userIsAdmin
                      }
                      dataCy={'dropdown-menu-button'}
                      onOpenChange={(isOpen) => {
                        setIsDropdownOpen(isOpen);
                        Logger.debug('isOpen', isOpen);
                      }}
                    >
                      <BaseDropdownItem
                        data-cy={'edit-item'}
                        onClick={(e) => {
                          e.stopPropagation();
                          setFolderEdit(item);
                        }}
                      >
                        Edit
                      </BaseDropdownItem>

                      <BaseDropdownItem
                        data-cy={'delete-item'}
                        onClick={(e) => {
                          e.stopPropagation();
                          setFolderDelete(item);
                        }}
                      >
                        Delete
                      </BaseDropdownItem>
                    </BaseDropdown>
                  </DropdownButtonContainer>
                )}
              </TableRow>
            ))}
          </TableCustom>
        )}
      </PageContent>
      <AddFolderModal
        typeFolder={constTypeFolder}
        onAccept={addFolder}
        onDecline={() => {
          setOpenAddFolderModal(false);
        }}
        open={!isDropdownOpen && openAddFolderModal}
        loading={loadingActions}
        zIndex={10}
      />

      {folderToEdit && (
        <EditFolderModal
          folder={folderToEdit}
          onAccept={editFolder}
          onDecline={handleDecline}
          open={!isDropdownOpen && folderToEdit !== null}
          loading={loadingActions}
          zIndex={10}
        />
      )}

      {folderDelete && (
        <FormModal
          dataCy="delete-folder-dialog"
          acceptText="Confirm"
          declineText="Cancel"
          open={!isDropdownOpen && isModalDeleteOpen}
          loading={loadingActions}
          mainText={`Are you sure you want to delete ${folderDelete.name} ?`}
          onAccept={removeFolder}
          onDecline={handleDeclineDelete}
          onOpenChange={handleDeclineDelete}
          zIndex={10}
        />
      )}
    </Container>
  );
};
