export const BASE_URL: string =
  import.meta.env.VITE_BACKEND_BASEURL ?? 'http://localhost:8080';

export const WEBSOCKET_BASE_URL =
  import.meta.env.VITE_BACKEND_WEBSOCKET_BASEURL ?? 'ws://localhost:3001';

export const IS_DEV = import.meta.env.DEV;

export const IS_PROD = !(
  IS_DEV ||
  window.location.hostname.includes('wip.') ||
  window.location.hostname.includes('dev.') ||
  window.location.hostname.includes('stg.')
);

export const NO_OMIT_COMMENTS = !IS_PROD;

export const GOOGLE_FONTS_API_URL: string =
  import.meta.env.VITE_GOOGLE_FONTS_API_URL ??
  'https://fonts.googleapis.com/css';

export const ROUTER_BASENAME: string =
  import.meta.env.VITE_ROUTER_BASENAME ?? '/';

export const COMPANY_ID: string = import.meta.env.VITE_GBS_COMPANY_ID ?? '1';

export const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN ?? '';
export const CLARITY_KEY: string = import.meta.env.VITE_CLARITY_KEY ?? '';
export const GOOGLE_TAG_MANAGER: string =
  import.meta.env.VITE_GOOGLE_TAG_MANAGER ?? '';

export const EDGE_URL =
  import.meta.env.VITE_EDGE_URL ?? 'http://localhost:5173';
export const ADMIN_URL =
  import.meta.env.VITE_ADMIN_URL ?? 'http://localhost:5174';
export const CLIENT_URL =
  import.meta.env.VITE_CLIENT_URL ?? 'http://localhost:5175';

export const UPLOAD_MAX_FILESIZE: string =
  import.meta.env.VITE_UPLOAD_MAX_FILESIZE ?? '50';

export const FREQUENCY_EDIT_TIME_UPDATE_SENT: number =
  import.meta.env.VITE_FREQUENCY_EDIT_TIME_UPDATE_SEND ?? 60000;
