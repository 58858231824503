import { type IFont } from '../pages/EditCourse/components/GlobalStyleModal';
import { type IAnalyticsReportData } from './analytics';
import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
  type IResponseDocumentData,
  api,
} from './api';
import { type ICoursePageDTO } from './coursePages';
import { type IDocumentDTO, type IUploadImageResponseData } from './documents';

export const globalDocumentPath = '/api/global-image';
export const courseDocumentPath = '/api/image-library-course/course';
export const templateDocumentPath = '/api/image-library-template/template';
export interface IFontDTO {
  family: string;
  variants?: string[];
  subsets?: string[];
  version?: string;
  lastModified?: string;
  category: string;
  kind?: string;
}
export interface IFontsList {
  fonts: IFontDTO[];
}

export interface ICourseSettingsProps {
  viewUrl: string | null;
  status: string | null;
  passcode?: string;
  passcodeProtected?: boolean;
}
export interface ICourseDTO {
  id: number;
  clientId: number;
  courseFile: IDocumentDTO | null;
  pages: ICoursePageDTO[];
  passcode?: string;
  passcodeProtected: boolean;
  title: string;
  viewURL: string | null;
  status: string | null;
  createdAt: string;
  updatedAt: string;
  fonts: IFontDTO[];
  globalStyle: IGlobalStyleDTO | null;
  analytics?: IAnalyticsReportData[];
}

export type ICourseWithAnalyticsDTO = ICourseDTO & {
  analytics?: IAnalyticsReportData[];
  oldAnalytics?: Record<string, number>;
};

interface ITemplateColors {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  accentColor: string;
  additionalColors: string[] | object;
}

export interface IGlobalStyleDTO {
  id: number;
  buttonColor: string | null;
  buttonFontColor: string | null;
  headlineFont: IFont | null;
  headlineFontColor: string | null;
  headlineFontSize: number | null;
  headlineFontSizeUnit: string | null;
  hueRotate?: number | null;
  pageWidth: number | null;
  pageWidthUnit: string | null;
  paragraphFont: IFont | null;
  paragraphFontColor: string | null;
  paragraphFontSize: number | null;
  paragraphFontSizeUnit: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  templateColors?: ITemplateColors;
}

export interface IGetCourseHistoryProps {
  courseId: number | string;
}

export interface IGetCourseRestoreProps {
  courseVersion: number | string;
}

export interface ICourseHistory {
  data: ICourseVersion[];
}

export interface ICourseVersion {
  id: number;
  course: ICourseDTO;
  courseVersion: number;
  createdAt: string;
}

export type ICourseContentId = Pick<ICourseDTO, 'id'>;

interface IDownloadCourse {
  id: number;
  language: string;
  orientation: string;
}

export interface IDownloadCourseDTO {
  document: {
    id: number;
    name: string;
    filename: string;
    extension: string;
  };
  message: string;
}

export interface ISaveCourseFontsProps {
  courseId: number;
  fonts: IFontDTO[];
}

export interface IDuplicateCourseProps {
  id: number;
  clientId: number;
}

export interface IGetDocumentIdProps {
  pageId: number;
}
export interface IImage {
  data: IDocumentDTO[];
}

export interface IUploadImageProps {
  file: Blob[] | File[];
  documentName: string[];
  pageId: number;
  clientId: number;
}

export type IGlobalUploadDocumentsProps = Omit<IUploadImageProps, 'pageId'>;

export type IUploadDocumentsProps = Omit<IUploadImageProps, 'clientId'>;

export type IChangeImageCourseProps = ICourseContentId & {
  newImage: File;
};

export type ICreateCourseProps = Partial<
  Pick<ICourseDTO, 'passcode' | 'passcodeProtected' | 'title' | 'viewURL'>
> &
  Pick<ICourseDTO, 'passcodeProtected'> & {
    companyId: string;
  };

export type ICreateCourseFromTemplatesProps = Partial<
  Pick<ICourseDTO, 'passcode' | 'passcodeProtected' | 'title' | 'viewURL'>
> &
  Pick<ICourseDTO, 'passcodeProtected'> & {
    companyId: string;
    pageTemplateIds: number[];
  };

export type IDeleteCourse = ICourseContentId;

export interface IPaginationCoursetDTO {
  data: ICourseDTO[];
  meta: IPaginationMetaProps;
}
export interface IGetCoursesByCompanyIdProps {
  companyId: string;
  page: number;
  limit: number;
  filter: string;
}

export type IGetCoursesByIdProps = Pick<ICourseDTO, 'id'>;

export type IUpdateCourseProps = Partial<
  Pick<
    ICourseDTO,
    'id' | 'passcode' | 'passcodeProtected' | 'status' | 'title' | 'viewURL'
  >
> & {
  id: number;
};

export type ICreateCourseUsingCourseGroupProps = Partial<
  Pick<ICourseDTO, 'passcode' | 'passcodeProtected' | 'title' | 'viewURL'>
> &
  Pick<ICourseDTO, 'passcodeProtected'> & {
    companyId: string;
    templateGroupId: number;
  };

export interface IUpdateEditTimeProps {
  courseId: number;
}

export interface IResponseUpdateEditTimeProps {
  userId: number;
  editingTime: number;
  updatedAt: string;
}

export const changeImageCourse = async ({
  id,
  newImage,
}: IChangeImageCourseProps): Promise<ICourseDTO> => {
  const data = new FormData();
  data.append('courseFile', newImage, newImage.name);
  const result = await api
    .post<IResponseData<ICourseDTO>>(`api/courses/${id}?_METHOD=PATCH`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const createCourse = async ({
  companyId,
  passcode,
  passcodeProtected,
  title,
  viewURL = '',
}: ICreateCourseProps): Promise<ICourseDTO> => {
  const data = new FormData();

  data.append('companyId', companyId);
  passcode !== undefined && data.append('passcode', passcode);
  passcodeProtected !== undefined &&
    data.append('passcodeProtected', String(passcodeProtected));
  title !== undefined && data.append('title', title);
  !!viewURL && data.append('viewURL', viewURL);

  const result = await api
    .post<IResponseData<ICourseDTO>>(`api/courses/`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const createCourseFromTemplates = async ({
  companyId,
  passcode,
  passcodeProtected,
  title,
  viewURL = '',
  pageTemplateIds,
}: ICreateCourseFromTemplatesProps): Promise<ICourseDTO> => {
  const data = new FormData();

  data.append('companyId', companyId);
  passcode !== undefined && data.append('passcode', passcode);
  passcodeProtected !== undefined &&
    data.append('passcodeProtected', String(passcodeProtected));
  title !== undefined && data.append('title', title);
  !!viewURL && data.append('viewURL', viewURL);
  data.append('pageTemplateIds', JSON.stringify(pageTemplateIds));

  const result = await api
    .post<IResponseData<ICourseDTO>>(`api/courses/template/`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteCourse = async ({
  id,
}: IDeleteCourse): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`api/courses/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const downloadCourse = async ({
  id,
  language,
  orientation,
}: IDownloadCourse): Promise<IDownloadCourseDTO> => {
  const data = new FormData();
  data.append('language', language);
  data.append('orientation', orientation);

  const result = await api
    .post<IResponseDocumentData>(`api/download-course/${id}`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const duplicateCourse = async ({
  id,
  clientId,
}: IDuplicateCourseProps): Promise<ICourseDTO> => {
  const data = new FormData();

  clientId !== undefined &&
    clientId > 0 &&
    data.append('clientId', String(clientId));

  const result = await api
    .post<IResponseData<ICourseDTO>>(`api/courses/duplicate/${id}`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getCoursesByCompanyId = async ({
  companyId,
  page,
  limit,
  filter,
}: IGetCoursesByCompanyIdProps): Promise<IPaginationCoursetDTO> => {
  const result = await api
    .get<IPaginationResponseData<ICourseDTO[]>>(
      `api/courses/company/${companyId}?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getCourseFonts = async ({
  id,
}: IGetCoursesByIdProps): Promise<IFontsList> => {
  const result = await api
    .get<IResponseData<IFontsList>>(`api/courses/${id}/fonts`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const saveCourseFonts = async ({
  courseId,
  fonts,
}: ISaveCourseFontsProps): Promise<ICourseDTO> => {
  const result = await api
    .post<IResponseData<ICourseDTO>>(`api/courses/${courseId}/fonts`, {
      fonts,
    })
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getCoursesById = async ({
  id,
}: IGetCoursesByIdProps): Promise<ICourseDTO> => {
  const result = await api
    .get<IResponseData<ICourseDTO>>(`api/courses/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateCourse = async ({
  id,
  passcode,
  passcodeProtected,
  title,
  viewURL = '',
  status = 'In Progress',
}: IUpdateCourseProps): Promise<ICourseDTO> => {
  const data = new FormData();

  passcode !== undefined && data.append('passcode', passcode);
  passcodeProtected !== undefined &&
    data.append('passcodeProtected', String(passcodeProtected));
  title !== undefined && data.append('title', title);
  viewURL !== null && data.append('viewURL', viewURL);
  status !== null && data.append('status', status);

  const result = await api
    .post<IResponseData<ICourseDTO>>(`api/courses/${id}?_METHOD=PATCH`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateGlobalStyleCourse = async ({
  id,
  ...globalstylecontent
}: IGlobalStyleDTO): Promise<IGlobalStyleDTO> => {
  const result = await api
    .patch<IResponseData<IGlobalStyleDTO>>(`api/global-styles/${id}`, {
      ...globalstylecontent,
    })
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export interface IUploadResponseData {
  data: IUploadImageResponseData;
}

export const globalGetDocuments = async (): Promise<IImage> => {
  const result = await api
    .get<IResponseData<IDocumentDTO[]>>(`${globalDocumentPath}/`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const globalUploadDocument = async ({
  file,
  documentName,
  clientId,
}: IGlobalUploadDocumentsProps): Promise<IUploadResponseData> => {
  const data = new FormData();
  file.forEach((f, index) => {
    data.append('files[]', f);
    data.append('documentName[]', String(documentName[index]));
  });
  data.append('clientId', String(clientId));
  const result = await api
    .post<IResponseData<IUploadImageResponseData>>(
      `${globalDocumentPath}/`,
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getCourseDocuments = async ({
  pageId,
}: IGetDocumentIdProps): Promise<IImage> => {
  const result = await api
    .get<IResponseData<IDocumentDTO[]>>(`${courseDocumentPath}/${pageId}`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const uploadDocumentCourse = async ({
  file,
  documentName,
  pageId,
}: IUploadDocumentsProps): Promise<IUploadResponseData> => {
  const data = new FormData();
  file.forEach((f, index) => {
    data.append('files[]', f);
    data.append('documentName[]', String(documentName[index]));
  });
  const result = await api
    .post<IResponseData<IUploadImageResponseData>>(
      `${courseDocumentPath}/${pageId}`,
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
  return result;
};

export const getTemplateDocuments = async ({
  pageId,
}: IGetDocumentIdProps): Promise<IImage> => {
  const result = await api
    .get<IResponseData<IDocumentDTO[]>>(`${templateDocumentPath}/${pageId}`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const uploadDocumentTemplate = async ({
  file,
  documentName,
  pageId,
}: IUploadDocumentsProps): Promise<IUploadResponseData> => {
  const data = new FormData();
  file.forEach((f, index) => {
    data.append('files[]', f);
    data.append('documentName[]', String(documentName[index]));
  });
  const result = await api
    .post<IResponseData<IUploadImageResponseData>>(
      `${templateDocumentPath}/${pageId}`,
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const restoreCourseHistory = async ({
  courseVersion,
}: IGetCourseRestoreProps): Promise<ICourseHistory> => {
  const result = await api
    .post<IResponseData<ICourseHistory>>(
      `/api/course-versions/${courseVersion}/restore-course`
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getCourseHistory = async ({
  courseId,
}: IGetCourseHistoryProps): Promise<ICourseHistory> => {
  const result = await api
    .get<IResponseData<ICourseVersion[]>>(
      `api/course-versions/course/${courseId}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const createCourseUsingCourseGroup = async ({
  companyId,
  passcode,
  passcodeProtected,
  title,
  viewURL = '',
  templateGroupId,
}: ICreateCourseUsingCourseGroupProps): Promise<ICourseDTO> => {
  const data = new FormData();

  data.append('companyId', companyId);
  passcode !== undefined && data.append('passcode', passcode);
  passcodeProtected !== undefined &&
    data.append('passcodeProtected', String(passcodeProtected));
  title !== undefined && data.append('title', title);
  !!viewURL && data.append('viewURL', viewURL);

  const result = await api
    .post<IResponseData<ICourseDTO>>(
      `api/courses/course-template/${templateGroupId}`,
      data
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getAutobuildMapping = async (): Promise<IResponseData> => {
  const result = await api
    .get<IResponseData>(`api/courses/auto-build/mapping`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateCourseEditTime = async ({
  courseId,
}: IUpdateEditTimeProps): Promise<IResponseUpdateEditTimeProps> => {
  const formData = new FormData();
  formData.append('courseId', String(courseId));

  const result = await api
    .post<IResponseUpdateEditTimeProps>(
      `api/courses/course/update-edit-time`,
      formData
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
