import {
  BaseRadioGroupIndicator,
  type IBaseRadioGroupProps,
} from '@gbs-monorepo-packages/common';

import { Flex, Label, RadioGroupItem, RadioGroupRoot } from './styles';

export interface IBaseRadioButtonProps
  extends Omit<IBaseRadioGroupProps, 'children'> {
  id?: string;
  dataCy?: string;
  onValue: (value: string) => void;
}

export const RadioButton = ({
  dataCy = 'radio-group-file-type',
  onValue,
  ...props
}: IBaseRadioButtonProps): JSX.Element => {
  return (
    <RadioGroupRoot
      defaultValue="files"
      aria-label="View density"
      data-cy={dataCy}
      {...props}
      onValueChange={(value) => {
        if (typeof value === 'string') {
          onValue(value);
        }
      }}
    >
      <Flex>
        <RadioGroupItem value="files" id="r1">
          <BaseRadioGroupIndicator />
        </RadioGroupItem>
        <Label htmlFor="r1">Files</Label>
      </Flex>
      <Flex>
        <RadioGroupItem value="images" id="r2">
          <BaseRadioGroupIndicator />
        </RadioGroupItem>
        <Label htmlFor="r2">Images</Label>
      </Flex>
      <Flex>
        <RadioGroupItem value="others" id="r3">
          <BaseRadioGroupIndicator />
        </RadioGroupItem>
        <Label htmlFor="r3">Videos</Label>
      </Flex>
    </RadioGroupRoot>
  );
};
